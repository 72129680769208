import React from "react"
import Layout from "../components/layout"
import { Container } from 'react-bootstrap/'
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"
import TopHeader from "../components/top-header"
import Img from "gatsby-image"
import styled from 'styled-components'


export default class Blog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
  }
  onChange = e => {

  };


  render() {
    const { data } = this.props
    const LinkedBlog = styled(Link)`
    &:hover{
      text-decoration: none;
    }
  `;
    const BlogOverViewWrapper = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top:1rem;
    @media (max-width: 768px) {
      display: grid;
      grid-template: initial;
    }
    `;
    const Wrapper = styled.div`
    width:100%
    `;
    const Title = styled.h1`
   font-weight:100
    `;

    return (<Layout>
      <TopHeader />
      <SEO title='Retmes Blog' />
      <Container className='content-container' >
        <Title><FormattedMessage id="media_retmes_h1" /><b className='bolder'><FormattedMessage id="retmes_blog" /></b></Title>
        <Wrapper>
          <BlogOverViewWrapper>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <LinkedBlog to={`${node.frontmatter.path}`}> <div className='guide-box' key={node.id}>
                {node.frontmatter.imageURL ?
                  <img src={node.frontmatter.imageURL} alt={node.frontmatter.title || node.frontmatter.BlogImageAlt} />
                  :
                  null
                }
                <div className='guide-content-wrapper'>
                  <h2 className='guide-box-title'>{node.frontmatter.title}</h2>
                  <p className='guide-box-desc'>{node.excerpt}</p>
                  <p className='guide-box-desc'>{node.frontmatter.date} <span className='content-info-divider'></span> {node.timeToRead} dk
                <Link className='guide-box-link' to={`${node.frontmatter.path}`}>Devamı</Link></p>
                </div>
              </div></LinkedBlog>
            ))}
          </BlogOverViewWrapper>
        </Wrapper>
      </Container>
    </Layout>
    )
  }
}

export const pageQuery = graphql`
query BlogPostsQuery {
  allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, filter: {frontmatter: {page: {ne: "default"}}}) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          path
          date(formatString: "LL", locale: "TR")
          imageURL
          BlogImageAlt
        }
        timeToRead
        id
        excerpt(pruneLength: 250)
      }
    }
    totalCount
  }
}        

`
